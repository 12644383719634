// Navs
.nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: $unit-1 0;

  .nav-item {
    a {
      color: $gray-color-dark;
      padding: $unit-1 $unit-2 $unit-1 0;
      text-decoration: none;
      &:focus,
      &:hover {
        color: $primary-color;
      }
    }
    &.active {
      & > a {
        color: $primary-color;
        font-weight: bold;

        &:focus,
        &:hover {
          color: darken($primary-color, 10%);
        }
      }
    }
  }

  & .nav {
    margin-bottom: $unit-2;
  }
}

.navHeader {
  color: $brand-color-secondary;
}
