.dragTargetIndicator--above {
  border-top: 2px dashed $primary-color;
}
.dragTargetIndicator--below {
  border-bottom: 2px dashed $primary-color;
}

[draggable="true"] * {
  // This prevents child elements of a draggable from receiving drag events.
  // That way only the draggable itself will receive the events, as they will
  // bubble up to it.
  pointer-events: none;
}
