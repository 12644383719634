.svgIcon {
  width: 1em;
  fill: $brand-color-primary;
}
.svgIcon--white {
  fill: white;
}
.btn-primary .svgIcon {
  @extend .svgIcon--white;
}

.svgIcon--2x {
  width: 2em;
}
.svgIcon--3x {
  width: 3em;
}
