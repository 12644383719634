// Carousels
// The number of carousel images
$carousel-number: 8;

%carousel-image-checked {
  animation: carousel-slidein 0.75s ease-in-out 1;
  opacity: 1;
  z-index: $zindex-1;
}

%carousel-nav-checked {
  color: $gray-color-light;
}

.carousel {
  background: $bg-color;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  z-index: $zindex-0;

  .carousel-container {
    height: 100%;
    left: 0;
    position: relative;
    &::before {
      content: "";
      display: block;
      padding-bottom: 56.25%;
    }

    .carousel-item {
      animation: carousel-slideout 1s ease-in-out 1;
      height: 100%;
      left: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;

      &:hover {
        .item-prev,
        .item-next {
          opacity: 1;
        }
      }
    }

    .item-prev,
    .item-next {
      background: rgba($gray-color-light, 0.25);
      border-color: rgba($gray-color-light, 0.5);
      color: $gray-color-light;
      opacity: 0;
      position: absolute;
      top: 50%;
      transition: all 0.4s;
      transform: translateY(-50%);
      z-index: $zindex-1;
    }
    .item-prev {
      left: 1rem;
    }
    .item-next {
      right: 1rem;
    }
  }

  .carousel-locator {
    @for $i from 1 through ($carousel-number) {
      &:nth-of-type(#{$i}):checked
        ~ .carousel-container
        .carousel-item:nth-of-type(#{$i}) {
        @extend %carousel-image-checked;
      }
    }

    @for $i from 1 through ($carousel-number) {
      &:nth-of-type(#{$i}):checked
        ~ .carousel-nav
        .nav-item:nth-of-type(#{$i}) {
        @extend %carousel-nav-checked;
      }
    }
  }

  .carousel-nav {
    bottom: $layout-spacing;
    display: flex;
    justify-content: center;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 10rem;
    z-index: $zindex-1;

    .nav-item {
      color: rgba($gray-color-light, 0.5);
      display: block;
      flex: 1 0 auto;
      height: $unit-8;
      margin: $unit-1;
      max-width: 2.5rem;
      position: relative;

      &::before {
        background: currentColor;
        content: "";
        display: block;
        height: $unit-h;
        position: absolute;
        top: 0.5rem;
        width: 100%;
      }
    }
  }
}

@keyframes carousel-slidein {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes carousel-slideout {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%);
  }
}
