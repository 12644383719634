.btn-secondary {
  background: $bg-color;
  border-color: darken($gray-color, 10%);
  color: darken($gray-color, 15%);
  &:hover {
    background: darken($gray-color, 20%);
    border-color: darken($gray-color, 20%);
    color: $light-color;
  }
  &:active,
  &.active {
    background: darken($gray-color, 20%);
    border-color: darken($gray-color, 20%);
    color: $light-color;
  }
}
