$primary-darkest: #003265; // TODO: Don't keep this here

.off-canvas {
  .off-canvas-toggle {
    font-size: 1rem;
    left: 1.5rem;
    position: fixed;
    top: 1rem;
  }
  .off-canvas-sidebar {
    width: 12rem;
  }
  .off-canvas-content {
    padding: 0;
  }
}

.app-navbar {
  height: 3.8rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  .btns {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
  }
}

.app-sidebar {
  .app-nav {
    bottom: 1.5rem;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: 1rem 1rem;
    position: fixed;
    top: 2.7rem;
    width: 12rem;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: $primary-darkest;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;

      &:hover {
        background: #555;
      }
    }


  }

  .subnav-separate {
    margin-top: 30px;
  }
}

.app-container {
  min-height: 100vh;
}

.app-content {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 2rem;
  width: calc(100vw - 12rem);

  & > .container {
    margin-left: 0;
    max-width: 1200px;
    padding-bottom: 1.5rem;

    padding-top: 5em;
  }
}
.app-brand {
  color: #5755d9;
  height: 1.6rem;
  position: fixed;
  top: 0.85rem;
  left: 1rem;

  .app-logo {
    align-items: center;
    border-radius: 0.1rem;
    display: inline-flex;
    display: -ms-inline-flexbox;
    -ms-flex-align: center;
    font-size: 0.7rem;
    height: 1.6rem;
    width: auto;
    left: 1rem;

    &:focus,
    &:hover {
      text-decoration: none;
    }
    img {
      display: inline-block;
      height: auto;
      width: 10rem;
    }
    h2 {
      display: inline-block;
      font-size: 0.8rem;
      font-weight: 700;
      line-height: 1.5rem;
      margin-bottom: 0;
      margin-left: 0.5rem;
      margin-right: 0.3rem;
    }
  }
}

@media (max-width: 960px) {
  .off-canvas {
    .off-canvas-toggle {
      z-index: 300;
    }
    .off-canvas-content {
      width: 100%;
    }
  }
  .app-sidebar {
    .app-brand {
      margin: 0.85rem 1.5rem;
      padding: 0;
      position: static;
    }
    .app-nav {
      margin-top: 1rem;
      position: static;
    }
    .menu .menu-item > a {
      padding: 0.3rem 0.4rem;
    }
  }
  .app-navbar {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(247, 248, 249, 0.65);
    left: 0;
  }
  .app-content {
    min-width: auto;
    padding: 0 1.5rem;
    width: 100%;

    & > .container {
      padding-top: 5rem;
    }

    @supports ((position: -webkit-sticky) or (position: sticky)) {
      .s-subtitle,
      .s-title {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 99;
      }
      .s-subtitle::before,
      .s-title::before {
        background: #fff;
        bottom: 0;
        content: "";
        display: block;
        left: -10px;
        position: absolute;
        right: -10px;
        top: -5px;
        z-index: -1;
      }
    }
  }
}

@media (max-width: 600px) {
  .off-canvas .off-canvas-toggle {
    left: 0.5rem;
  }
  .app-sidebar {
    .app-brand {
      margin: 0.85rem 1rem;
    }
    .app-nav {
      padding: 0.5rem 1rem;
    }
  }
  .app-content {
    padding: 0 0.5rem;

    .app-block {
      padding: 0.4rem 0.1rem;
    }
  }
}
