// Progress
// Credit: https://css-tricks.com/html5-progress-element/
.progress {
  appearance: none;
  background: $bg-color-dark;
  border: 0;
  border-radius: $border-radius;
  color: $primary-color;
  height: $unit-1;
  position: relative;
  width: 100%;

  &::-webkit-progress-bar {
    background: transparent;
    border-radius: $border-radius;
  }

  &::-webkit-progress-value {
    background: $primary-color;
    border-radius: $border-radius;
  }

  &::-moz-progress-bar {
    background: $primary-color;
    border-radius: $border-radius;
  }

  &:indeterminate {
    animation: progress-indeterminate 1.5s linear infinite;
    background: $bg-color-dark
      linear-gradient(to right, $primary-color 30%, $bg-color-dark 30%) top left /
      150% 150% no-repeat;

    &::-moz-progress-bar {
      background: transparent;
    }
  }
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
