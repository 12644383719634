// Tables
.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  @if $rtl == true {
    text-align: right;
  } @else {
    text-align: left;
  }

  &.table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background: $bg-color;
      }
    }
  }

  &,
  &.table-striped {
    tbody {
      tr {
        &.active {
          background: $bg-color-dark;
        }
      }
    }
  }

  &.table-hover {
    tbody {
      tr {
        &:hover {
          background: $bg-color-dark;
        }
      }
    }
  }

  // Scollable tables
  &.table-scroll {
    display: block;
    overflow-x: auto;
    padding-bottom: 0.75rem;
    white-space: nowrap;
  }

  td,
  th {
    border-bottom: $border-width solid $border-color;
    padding: $unit-3 $unit-2;
  }
  th {
    border-bottom-width: $border-width-lg;
  }
}
