// Position
.clearfix {
  @include clearfix();
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-sticky {
  position: sticky !important;
}

.p-centered {
  display: block;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.flex-centered {
  align-items: center;
  display: flex;
  justify-content: center;
}

// Spacing
@include margin-variant(0, 0);
@include margin-variant(1, $unit-1);
@include margin-variant(2, $unit-2);
@include margin-variant(3, $unit-3);
@include margin-variant(4, $unit-4);
@include margin-variant(5, $unit-5);
@include margin-variant(6, $unit-6);

@include padding-variant(0, 0);
@include padding-variant(1, $unit-1);
@include padding-variant(2, $unit-2);
@include padding-variant(3, $unit-3);
@include padding-variant(4, $unit-4);
@include padding-variant(5, $unit-5);
@include padding-variant(6, $unit-6);
