.section {
  margin-bottom: $layout-spacing;
}

.section__title {
  @extend .h5;
  margin-bottom: 0;
  color: $primary-color;
}

.section__controls {
  padding-bottom: $layout-spacing-lg;
}

.section--break {
  padding-top: $layout-spacing-xl;
  padding-bottom: $layout-spacing-lg;

  .section__title {
    padding-bottom: $layout-spacing-lg;
    border-top: 4px solid $primary-color-light;
  }
}
