@import 'components/colors';

// Material-ui shadow 1
$shadow1: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);

// wildcard selector for FormBuilder Form Headers
[class^="formBuilder"] {

  font-family: 'Roboto', sans-serif;

  [class^="formHead"] {
    background-color: $primary;
    color: $light;
    width: 100% !important;

    div {
      width: 100% !important;
    }

    .form-control {
      border: 1px solid $light !important;
      border-radius: 4px;
    }
  }

  .form-field-name {
    width: 100% !important;
  }

  .form-section-name {
    width: 66% !important;
  }

  input[type=checkbox] {
    height: 0.6rem;
  }

  .card-enum {
    background-color: #fff !important;
    box-shadow: $shadow1
  }

  .section-container, .card-container {
    h4 {
      .label {
        font-size: 1.1rem !important;
        background: none !important;
      }
    }
  }
}

// TODO: Big risk of conflicts, but needed because modals aren't attached in a bootstrap-scope-able part of the DOM
//       This applies to the advanced settings modal, which is currently disabled in our Dynamic Forms builder as of
//       September 2023
//.modal {
//  display: flex !important;
//  ali
//  gn-items: center;
//  background-color: rgba(33, 33, 33, 0.5);
//}


// Wildcard for FormBuilder modals (from field overflow settings)
[class*="cardModal"] {
  background-color: white !important;
  box-shadow: $shadow1 !important;
  padding: 1rem;
  flex: 1;
  margin: auto;
  align-items: center;

  h3 {
    font-size: 1.3rem !important;
  }

  h4 {
    font-size: 1rem !important;
  }

  button {
    margin-right: 0.5rem;
  }
}

.tooltip {
  z-index: 1060;
  background-color: white;
  box-shadow: $shadow1;
  padding: 1rem;
  margin-bottom: 0.5rem;
  color: $dark;
}

.popover {
  padding: 1rem;
  color: $dark;
  box-shadow: $shadow1;
  background-color: white;
  margin-top: 0.5rem;

  &-inner {
    border: 0 !important;
  }
}

// The wrapper for the bootstrap-scoped form builder
.bootstrap-scope {
  .section-container, .card-container {
    box-shadow: $shadow1;
    background-color: white;
    padding: 1rem;
    border: 0 !important;
    margin: 1rem auto !important;
  }

  .section-container {
    width: 100% !important;
  }

  .card-container {
    width: 95% !important;
  }

  .section-head {
    border-bottom: 0 !important;
  }

  .section-interactions {
    border-top: 0 !important;
  }

  .section-entry {
    input[type=text] {
      border: 0.5px solid $primary !important;
      border-radius: 4px;
    }
  }

  .card-entry {
    input[type=text] {
      border: 0.5px solid $primary !important;
      border-radius: 4px;
    }

    // No border for select fields with typeaheads, because there is an inner-nested text input
    .card-select {
      input[type=text] {
        border: 0 !important;
      }
    }
  }

  .card-category-options {
    input[type=text] {
      border: 0.5px solid $primary !important;
      border-radius: 4px;
    }
  }

  .section-container .d-flex {
    border-bottom: 0 !important;
  }

  .card-container .d-flex {
    border-bottom: 0 !important;
  }

  [class^="cardEntries"] {
    border-bottom: 0 !important;
  }

  // Icon sizes
  .toggle-collapse {
    svg {
      height: 25px !important;
      padding: 15px !important;
      margin-bottom: 12px !important;
      color: $dark !important;
    }
  }

  .arrows {
    svg {
      height: 20px !important;
      width: 20px !important;
      color: $dark !important;
    }
  }
}
