// Panels
.panel {
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
}

.panel-header,
.panel-footer {
  flex: 0 0 auto;
  padding: $layout-spacing-lg;
}

.panel-nav {
  flex: 0 0 auto;
}

.panel-body {
  flex: 1 1 auto;
  overflow-y: visible;
  padding: 0 $layout-spacing-lg;
}

.panel--noBorder {
  border: none;

  .panel-header,
  .panel-footer {
    padding: $layout-spacing-lg 0;
  }

  .panel-body {
    padding: 0;
  }
}
