.pagination-tiny {
  font-size: 0.8em;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 400px) {
  .pagination-tiny .page-item a {
    padding: 0.2rem 0.22rem;
  }
}
