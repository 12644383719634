body.reports-assistances {
  .app-content {
    overflow-x: auto;
  }
}
#assistance_report {
  td {
    border-bottom: none;
  }

  tr.summary {
    color: $primary-color;
    td {
      font-style: italic;
      border-bottom: 1px solid #cccccc;
    }
    td.description {
      text-align: right;
    }
  }

  tbody tr:last-child {
    font-weight: bold;
  }
}
