@use "sass:math";

// Sliders
// Credit: https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/
.slider {
  appearance: none;
  background: transparent;
  display: block;
  width: 100%;
  height: $unit-6;

  &:focus {
    @include control-shadow();
    outline: none;
  }

  &.tooltip:not([data-tooltip]) {
    &::after {
      content: attr(value);
    }
  }

  // Slider Thumb
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: $primary-color;
    border: 0;
    border-radius: 50%;
    height: $unit-3;
    margin-top: math.div(-($unit-3 - $unit-h), 2);
    transition: transform 0.2s;
    width: $unit-3;
  }
  &::-moz-range-thumb {
    background: $primary-color;
    border: 0;
    border-radius: 50%;
    height: $unit-3;
    transition: transform 0.2s;
    width: $unit-3;
  }
  &::-ms-thumb {
    background: $primary-color;
    border: 0;
    border-radius: 50%;
    height: $unit-3;
    transition: transform 0.2s;
    width: $unit-3;
  }

  &:active {
    &::-webkit-slider-thumb {
      transform: scale(1.25);
    }
    &::-moz-range-thumb {
      transform: scale(1.25);
    }
    &::-ms-thumb {
      transform: scale(1.25);
    }
  }

  &:disabled,
  &.disabled {
    &::-webkit-slider-thumb {
      background: $gray-color-light;
      transform: scale(1);
    }
    &::-moz-range-thumb {
      background: $gray-color-light;
      transform: scale(1);
    }
    &::-ms-thumb {
      background: $gray-color-light;
      transform: scale(1);
    }
  }

  // Slider Track
  &::-webkit-slider-runnable-track {
    background: $bg-color-dark;
    border-radius: $border-radius;
    height: $unit-h;
    width: 100%;
  }
  &::-moz-range-track {
    background: $bg-color-dark;
    border-radius: $border-radius;
    height: $unit-h;
    width: 100%;
  }
  &::-ms-track {
    background: $bg-color-dark;
    border-radius: $border-radius;
    height: $unit-h;
    width: 100%;
  }
  &::-ms-fill-lower {
    background: $primary-color;
  }
}
