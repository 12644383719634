// Meters
// Credit: https://css-tricks.com/html5-meter-element/
.meter {
  appearance: none;
  background: $bg-color;
  border: 0;
  border-radius: $border-radius;
  display: block;
  width: 100%;
  height: $unit-4;

  &::-webkit-meter-inner-element {
    display: block;
  }

  &::-webkit-meter-bar,
  &::-webkit-meter-optimum-value,
  &::-webkit-meter-suboptimum-value,
  &::-webkit-meter-even-less-good-value {
    border-radius: $border-radius;
  }

  &::-webkit-meter-bar {
    background: $bg-color;
  }

  &::-webkit-meter-optimum-value {
    background: $success-color;
  }

  &::-webkit-meter-suboptimum-value {
    background: $warning-color;
  }

  &::-webkit-meter-even-less-good-value {
    background: $error-color;
  }

  &::-moz-meter-bar,
  &:-moz-meter-optimum,
  &:-moz-meter-sub-optimum,
  &:-moz-meter-sub-sub-optimum {
    border-radius: $border-radius;
  }

  &:-moz-meter-optimum::-moz-meter-bar {
    background: $success-color;
  }

  &:-moz-meter-sub-optimum::-moz-meter-bar {
    background: $warning-color;
  }

  &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
    background: $error-color;
  }
}
