.authscreen__logo {
  max-width: 60%;
  margin-bottom: 48px;
}

.btn.btn-link.authscreen__classic-link {
  color: white;
}

.authscreen__overlay {
  background: radial-gradient(#40404b, #111118) rgba(34, 34, 40, 0.94);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0.9;
}
