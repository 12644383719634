#flash-messages {
  .toast {
    &:not(:first-child) {
      margin-top: 0.4rem;
    }

    // add clearance below the last flash notice
    // this way if there aren't any flash messages,
    // there the flash messages container won't
    // use up any extra room
    &:last-child {
      margin-bottom: 1.6rem;
    }
  }
}
