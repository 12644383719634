@import "../colors";
.nav-header {
  color: #fff;
  &:not(:first-of-type) {
    margin-top: 24px !important;
  }
}

.nav-item {
  color: $light;
  font-size: 0.77rem;
  font-weight: 400;
  margin-bottom: 8px !important;

  &.active{
    a {
      color: $primary !important;
      font-weight: 500;
    }
  }

  a {
    text-decoration: none !important;
    color: $light;

    &:hover{
      color: $primary !important;
      //opacity: 0.8 !important;
    }

    &:visited{
      color: $light;
    }
  }

}