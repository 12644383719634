// Loading
.loading {
  color: transparent !important;
  min-height: $unit-4;
  pointer-events: none;
  position: relative;
  &::after {
    animation: loading 500ms infinite linear;
    background: transparent;
    border: $border-width-lg solid $primary-color;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: $unit-4;
    left: 50%;
    margin-left: -$unit-2;
    margin-top: -$unit-2;
    opacity: 1;
    padding: 0;
    position: absolute;
    top: 50%;
    width: $unit-4;
    z-index: $zindex-0;
  }

  &.loading-lg {
    min-height: $unit-10;
    &::after {
      height: $unit-8;
      margin-left: -$unit-4;
      margin-top: -$unit-4;
      width: $unit-8;
    }
  }
}
