// FAB - Floating Action Button control

.fab__container {
  position: fixed;
  right: 24px;
  bottom: 24px;
}

.fab__btn {
  height: 100%;
  display: inline-block;
  padding: 7px 24px 4px;
  border-radius: 48px;
  color: #fff;
  font-size: 15px;

  // shadow to give a sense of depth in the UI
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    cursor: pointer;
  }

  &:focus {
    @include control-shadow();
  }
  &:focus,
  &:hover {
    background: $secondary-color;
    border-color: $primary-color-dark;
    text-decoration: none;
  }
  &:active,
  &.active {
    background: $primary-color-dark;
    border-color: darken($primary-color-dark, 5%);
    color: $light-color;
    text-decoration: none;
  }
}

.fab__btn i,
.fab__label {
  display: inline-block;
  font-size: 28px;
}

.fab__label {
  font-size: 28px;
  margin-left: 4px;
}

@media (max-width: $size-sm) {
  .fab__btn i,
  .fab__label {
    font-size: 20px;
  }
  .fab__label.scrollingDown {
    display: none;
  }
}
