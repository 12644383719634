// Label base style
@mixin label-base() {
  border-radius: $border-radius;
  line-height: 1.25;
  padding: 0.1rem 0.2rem;
}

@mixin label-variant($color: $light-color, $bg-color: $primary-color) {
  background: $bg-color;
  color: $color;
}
