@import "../colors";

.dyno-form-viewer {

  .section-content {
    padding-top: 6px;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  .section-header {

    &.MuiTypography-h4 {
      font-size: 1.25rem !important;
      font-weight: 700 !important;
    }

    &.MuiTypography-h5 {
      font-size: 1.15rem !important;
      font-weight: 700 !important;
    }

    &.MuiTypography-h6 {
      font-size: 1.05rem !important;
      font-weight: 700 !important;
    }
  }

  .element-header {
    &.MuiTypography-h4 {
      font-size: 1.05rem !important;
      font-weight: 500 !important;
    }

    &.MuiTypography-h5 {
      font-size: 0.95rem !important;
      font-weight: 500 !important;
    }

    &.MuiTypography-h6 {
      font-size: 0.87rem !important;
      font-weight: 500 !important;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    padding-top: 6px;
    margin-top: 8px;
  }
}